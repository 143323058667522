import React from 'react'
import { Text, Flex } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

/**
 * Custom Shadowed Override
 */

const styles = {
    imageWrapper: {
        borderRadius: `default`,
        overflow: `hidden`,
        position: `relative`
    },
    button: {
        display: [`none`, `block`],
        position: `absolute`,
        bottom: 4,
        right: 4
    },
    grid: {
        flexWrap: [`wrap`, null, `nowrap`],
        ' > div + div': {
            ml: [0, 0, 5]
        }
    },
    column: {
        flex: `auto`,
        flexBasis: [`full`, null, `1/2`]
    }
}

export default props => {
    return (
        <Layout {...props}>
            <Seo title='About' />
            <Divider />
            <Stack>
                <Main>
                    <PageTitle
                        header='About Me'
                        subheader='Software Engineer, Web Developer, and Entrepreneur'
                    />
                    <Divider />
                    <Divider />
                    <Flex sx={styles.grid}>
                        <Text variant='p'>
                            I should probably find the time to update this.
                        </Text>
                    </Flex>
                </Main>
            </Stack>
        </Layout>
    )
}
